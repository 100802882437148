import React from "react";
//import { Link } from "gatsby"
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import './assets/styles/_index.scss';
import { useTranslation } from "react-i18next"

const BreadcrumbModule = (props) => {
    const { t } = useTranslation()
    return (
        <div className="breadcrumb-module-wrapper">
            <Container>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <Link to="/" className="breadcrumb-item">{t("home")}</Link>
                            {props.parent && <Link to={`/${props.parent}/`} className="breadcrumb-item">{props.parentname}</Link> }
                            {props.subparent && <Link to={`/${props.subparent}/`} className="breadcrumb-item">{props.subparentname}</Link> }
                            <Breadcrumb.Item active>{props.pagename}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BreadcrumbModule